.partnerListItem {
    padding:0px !important;
    border-block-end:0px !important;
  }
  .partnerButton {
    padding-top: 10px;
    padding-bottom: 30px;
    border-radius: 20px 20px 20px 20px;
    margin-top: 15px;
    justify-content: center !important;
    background-color: #1d40bc;
  
  }
  
.zoho-types{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 0 20px;
    max-width: 1080px;
    margin: 0 auto;
  
  }
  .blobOne{
    position: absolute;
    top: 20%;
    right: -190px;
    width: 280px;
    z-index: 0;
    opacity: .15;
  }
  .blobSec{
    position: absolute;
    top: 10%;
    left: -190px;
    width: 380px;
    z-index: 0;
    opacity: .15;
  }
  .blobThird{
    position: absolute;
    top: -20px;
    left: -120px;
    width: 90px;
    z-index: 0;
    opacity: .15;
  }
  .blobFour{    position: absolute;
    top: -22px;
    left: 180px;
    width: 130px;
    z-index: 0;
    opacity: .15;
  }
  .blobFive {
    position: absolute;
    bottom: 50px;
    right: -104px;
    width: 130px;
    z-index: 0;
    opacity: .15;
  }