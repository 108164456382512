.aboutSpace {
    padding: 25px;
}

.overlay-text {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white !important;
    border-radius: 5px;
  }
  
  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;

  }
  .CardShadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .article {
    height: 100%;
    position: relative;
    overflow: hidden;
}
.header {
  font-size: 40px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

@media (max-width: 500px) {
  .overlay-textMedia {
    font-size: 13px; /* Adjust the font size as needed for mobile view */
    text-shadow: 4px 4px 4px rgba(40, 51, 81, 59)
  }
  .overlay-text{
    position: absolute;
    top: 88%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white !important;
    border-radius: 5px;
    font-size: 12px !important;
    width: 84%;
  }
}
  