.NavBarTop{
  padding-bottom: 20px;
}
.App {
  text-align: center;
  padding: 0px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#testimonials {
    padding: 60px 0;
  }
  
  #testimonials .section-headerBelowSpace {
    margin-bottom: 10px;
  }
  .testimonialCard{
    margin: 5%;
    width: 80%;
    height: 325px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  }
  #testimonials .testimonial-item {
    text-align: center;
  }
  
  #testimonials .testimonial-item .testimonial-img {
    width: 120px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
  }
  
  #testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  #testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0 0 15px 0;
  }
  
  #testimonials .testimonial-item .quote-sign-left {
    margin-top: -15px;
    padding-right: 10px;
    display: inline-block;
    width: 37px;
  }
  
  #testimonials .testimonial-item .quote-sign-right {
    margin-bottom: -15px;
    padding-left: 10px;
    display: inline-block;
    max-width: 100%;
    width: 37px;
  }
  
  #testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
  }
  
  @media (min-width: 992px) {
    #testimonials .testimonial-item p {
      width: 80%;
    }
  }
  
  #testimonials .owl-nav, #testimonials .owl-dots {
    margin-top: 5px;
    text-align: center;
  }
  
  #testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd;
  }
  
  #testimonials .owl-dot.active {
    background-color: #18d26e;
  }
  .topPad {
    padding-top: 40px;
  }
  .opacityFacts {
    opacity: 0.5 !important;
  }
  .backColor {
    background-color: white;
  }
  .bottomPad {
    padding-bottom: 20px;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: #484848;
  }
  .testimoialQuates {
    padding-bottom: 20px;
    font-weight: normal;
    font-size: 15px;
    text-align: left;
  }
  .backColorContact {
    background-color: #D9D9D9;
    opacity: .15;
  }
  .backColorFooter {
    background-color: black;
    color: #1D1D1D;
  }

  .footer {
    background: #0E1330;
    padding: 0 0 30px 0;
    color: white;
    font-size: 14px;
  }
  
  .footer .footer-top {
    background: #262626;
    padding: 60px 0 30px 0;
  }
  
  .footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  .footer .footer-top .footer-info .footerheader {
    font-size: 34px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 10px;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    border-left: 4px solid #18d26e;
    text-align: left;
    margin-left: 5%;
    color: white;
  }
  
  .footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
    color: white;
    width: 75%;
    text-align: left;
    margin-left: 5%;
  }
  
  .textColorwhite{
    color: white !important;
  }
  .textColorwhite:hover{
    color: #18d26e !important;
  }
  .footerUsefulLinksList {
    color: #18d26e;
    padding-left: 15px;
    padding-bottom: 20px;
  }
  .footerUsefulLinksHeader{
    color: #18d26e;
    font-size: 20px !important;
    padding-bottom: 20px;
  }
  .footerUsefulLinksListIcons{
    color: #1D1D1D;
    padding-top: 15px;
    padding-bottom: 20px;
  }
  
  .footerUsefulLinksListIcons svg{
    font-size: xx-large;
  }
  .footerUsefulLinksHeader svg{
    font-size: xx-large;
  }
  .footerUsefulLinksList svg{
    font-size: xx-large;
  }
  .contactSectionTobAndBottomPadding{
    padding-top: 70px;
    padding-bottom: 70px;
  }

.factNumberSize span{
  font-size: 32px !important;
  color: #0085FF !important;
  font-family: "Montserrat", sans-serif;
}
.starIcon {
  width: 20px; /* Adjust the size as needed */
  height: 20px;
  vertical-align: middle;
  margin-right: 2px; /* Add spacing between the star and the rating */
}
.factTitleSize{
  font-size: 18px !important;
  color: white !important;
}
.oappsChannelTitle{
  background-color:#060B27;
  padding-top: 40px;
  padding-bottom: 40px;
}
.oappsChannelImage{
  width: 100%;
  margin-top:-140px;
}
.oappsChannelImageNormaView{
  display: block;
}
.oappsChannelImageMoblieView{
  display: none;
}


.oappsFeatureBackgruound{
  background-color: #F7F7FB;
  padding-top: 25px !important;
}
.oappsFeatureLearnMore{
  margin-top: 40px;
}
.testimonialBackground{
  background-color: white;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}

.usefulLinks{
  height: 30px;
  margin-top: 20px;
  width: 100%;
}

.usefullinkText h4{
  width: max-content;
}
.oappsFooterText{
  padding-left: 15px;
  color: white;
  line-height: 20px;
  text-align: left;
}
.footerContactUsHeader{
  width: 100%;
  margin-left: 35px !important;
}
.footerContactUsList{
  text-align: justify;
  line-height: 30px;
  padding-left: 55px;
  width: 100%;
  color: white !important;
}
.footerSocialIcons{
  padding-left: 30px;
  margin-left: 25px;
}

.footerSecondCol{
  padding-left: 30px;
}
.oappsChannelBackground{
  height: 250px;
  margin-top: 4%;
  padding-bottom: 10%;
}
.channelLogoNormalView{
  display: block;
}
.channelLogoMobileView{
  display: none;
}
.ProviderBackground{
background-image: url("./img/ProviderBackground.png");
background-position:right;
background-repeat: no-repeat;
background-size: 50%;
padding-top: 30px;
padding-bottom: 40px;

}
.ProviderLogosNormalView{
  display: block;
}
.ProviderLogosMobileView{
  display: none;
}
.oappsChannelLogo{
  font-size: 40px;
  opacity: 0.6 !important;
}
.ColorForContact{
  color: #1D1D1D;
}
.animated-element {
  color: red;
}
.footerFileIcon svg{
  font-size: 15px;
}
.usefullinkText svg{
  font-size: 15px;
}


@media (max-width: 500px) {
  .oappsChannelLogo{
    font-size: 40px;
  }
  .oappsChannelTitle{
    padding: 0px !important;
    font-size: 15px !important;
    margin-top: 0px !important;
  }
  .oappsChannelRow{
    padding: 0px !important;
  }
  .oappsChannelBackground{
    margin-top: 10px;
    height: 125px;
  }
  .channelWeSupport{
    margin-top: 25px !important;
  }

  .section-headerTestimonials{
    text-align: center;
    padding-top: 15px !important;
    margin-bottom: 32px;
  }
  .mobileViewOappsFeatureCard{
    height: 300px !important;
    width: 100% !important;
  }
  .mobileViewOappsFeatureCardIcon{
    margin: 0px !important;
  }
  .oappsFeatureLearnMore{
    margin-top: 10px;
  }
  .mobileViewOappsFeatureCardSize{
    padding-left:10px !important;
    padding-right: 10px !important;
  }
  .ProviderBackground{
    background-image: url("./img/ProviderBackground.png") !important;
    background-size: 203%;
    background-position:top right;
    background-position-x:-21px ;
    height: 439px;
    padding-top: 46px;
    margin-bottom: 0px;
  }
  .factTitleSize{
    font-size: 12px !important;
  }
 .oappsChannelRow img {
  margin-top: -18px !important;
  width: 107%;
 }
 #testimonials{
  padding-bottom: 10px !important;
 }
 .testimonial-img {
  width: 30% !important;
}
.bottomPad{
  margin: 0px !important;
  color: #484848;
}
.testimoialQuates{
  margin: 0px !important;
}
.testimonialCard{
  width: 90% !important;
  margin: 0px;
  margin-bottom: 25px;
  height: 390px;
}
.testimonialCard .ant-card-body{
  margin-bottom: 50px;
}
.oappsChannelImageNormaView{
  display: none;
}
.oappsChannelImageMoblieView{
  display: block;
}
.factNumberSize span{
  font-size: 20px !important;
  color: #0085FF !important;
  font-family: "Montserrat", sans-serif;
}
.footerUsefulLinksListIcons a{
  text-align: center;
  padding-left: 50px;
}
.pipedrive-image-position {
  width: 142px; height: 89px;
  background-position: -720px -414px !important;
  zoom: 0.37;
  margin-left: 49% !important;
  margin-top: -46% !important;
  
}
.twilio-image-position {
  width: 111px; height: 114px;
  background-position: -11px -813px !important;
  zoom: 0.31;
  margin-left: 24% ;
  margin-top: -46% ;
  
}
.ProviderLogosNormalView{
  display: none;
}
.ProviderLogosMobileView{
  display: block;
}
.channelLogoNormalView{
  display: none;
}
.channelLogoMobileView{
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.footerSocialIcons{
  padding-left: 0px;
  margin-left: 0px;
}
}


